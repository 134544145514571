import * as React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { ThemeProvider } from '@emotion/react'
import { GlobalTheme } from './styles/theme'
import { GlobalStyles } from './styles/global'


const queryClient = new QueryClient()

export const Root: React.FC<{ children: React.ReactNode }> = props => {
  return (
    <QueryClientProvider client={queryClient}>
      <GoogleReCaptchaProvider reCaptchaKey={process.env.GATSBY_RECAPTCHA_SITE_KEY ?? ''}>
        <ThemeProvider theme={GlobalTheme}>
          {GlobalStyles}
          {props.children}
        </ThemeProvider>
      </GoogleReCaptchaProvider>
    </QueryClientProvider>
  )
}
