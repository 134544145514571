import React from 'react'
import { css, Global } from '@emotion/react'

export const GlobalStyles = (
  <Global
    styles={css`
      @import url("https://use.typekit.net/xww4yfc.css");
      
      * {
        box-sizing: border-box;
      }

      html {
        min-height: 100vh;
        min-height: -webkit-fill-available;
      }

      body {
        font-family: "proxima-nova", "Open Sans", "Gill Sans MT", "Gill Sans", Corbel, Arial, sans-serif;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: stretch;
        margin: 0;

        min-height: 100vh;
        min-height: -webkit-fill-available;
      }

      #___gatsby {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: stretch;

        min-height: 100vh;
      }

      #gatsby-focus-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;

        flex: 1;
      }

      .grecaptcha-badge {
        visibility: hidden;
      }
    `}
  />
)
