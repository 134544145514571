import { Theme } from '@emotion/react'

export const GlobalTheme: Theme = {
  colors: {
    primary: '#131313',
    black: '#131313',
    lightGray: '#F1F3F5',
    blue: '#032976',
    gray: '#3C4A5D',
    slateGray: '#E7E7E8',
    red: 'darkred',
    white: '#fff',
    yellow: '#FFB020',
    green: '#52BD95',
  },
  font: {
    primary: `"proxima-nova", "Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif`,
  },
  fontWeight: {
    light: 300,
    normal: 400,
    // semiBold: 500,
    bold: 600,
    extraBold: 700,
  },
}
